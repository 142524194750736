@use "../../colors" as *;
@use "../../spacing" as *;

aside {
  @apply md:w-415 w-full;
  border: 1px solid $border;
  display: flex;
  flex-direction: column;
  flex: 0;
  border-radius: $border-radius;
  gap: 24px;
  padding: $p-md;
  box-sizing: border-box;
  top: 100px;

  .ctas {
    display: flex;
    flex-direction: column;
    gap:$gap-12;
    height: 124px;
    flex-shrink: 0;

    button {
      @apply w-full md:w-352;
    }
  }

  .apply-details {
    display: flex;
    flex-direction: column;
    gap: $gap-12;
    text-align: left;

    .apply-detail {
      display: flex;
      flex-direction: column;
    }
  }

  .post-date {
    opacity: .6;
    text-align: left;
  }
}
