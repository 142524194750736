@use '../../colors' as *;
@use '../../functions' as *;

.attachments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 364px));
  gap: 24px;

  .attachment {
    width: 364px;
    height: 58px;
    background: $primary-light;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 16px 15px 16px;
    border-radius: 8px;
    box-sizing: border-box;
    color: $primary;

    @media (max-width: 640px) {
      width: 100%;
    }

    span {
      display: inline-flex;

      &.name {
        padding: 0 0 0 adjust-for-icon-24(0, 8px);
        background-image: url('../../assets/icons/24/download-blue.svg');
        background-repeat: no-repeat;

      }
    }
  }
}
