@use '../../colors' as *;
@use '../../spacing' as *;

#home {
  header {
    background: $primary url("../../assets/backgrounds/hoot.svg") no-repeat -155px -85px;

    @media screen and (min-width: 1100px) {
      background: $primary url("../../assets/backgrounds/hoot.svg") no-repeat right;
    }

    @media screen and (max-width: 450px) {
      background-size: 140%;
    }
    //min-width: 1500px !important;
  }


  }

.view-more {
  display: flex;
}

.view-more  {
  align-items: center;
  justify-content: center;

  button {
    width: 1000px;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
}

.recent-header {
  @apply text-center translate-x-minus82;

  @media screen and (min-width: 768px) {
    @apply translate-x-minus110;
  }

  @media screen and (min-width: 998px) {
    @apply translate-x-minus335;
  }

  @media screen and (min-width: 1449px) {
    @apply translate-x-minus560;
  }

  @media screen and (min-width: 1900px) {
    @apply translate-x-minus785;
  }

  @media screen and (min-width: 2351px) {
    @apply translate-x-minus1010;
  }

  @media screen and (min-width: 2802px) {
    @apply translate-x-minus1235;
  }
}

.job-details main {
  @apply px-0;

}

  main {
    @apply px-8 md:px-16 pt-10 pb-15;
    display: flex;
    flex-direction: column;




    .view-more {
      display: flex;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $gap-32;
  }

  button {
    cursor: pointer;
  }

  .react-aria-ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--visual-viewport-height);
    background: rgba(0 0 0 / .5);
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-entering] {
      animation: modal-fade 200ms;
    }

    &[data-exiting] {
      animation: modal-fade 150ms reverse ease-in;
    }
  }

  .react-aria-Modal {
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    background: var(--page-background);
    border: 1px solid var(--spectrum-global-color-gray-300);
    outline: none;


    &[data-entering] {
      animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .react-aria-TextField {
      margin-bottom: 8px;
    }
  }

  @keyframes modal-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-zoom {
    from {
      transform: scale(0.8);
    }

    to {
      transform: scale(1);
    }
  }

