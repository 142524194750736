@use 'colors' as *;
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-body-16 font-normal text-std-text
}

input[type="text"], input[type="search"], input[type="password"] {
  @apply h-14 basis-full h-14
}

button {
  cursor: pointer;
  background: none;
}

#root {
  width: 100%;
}

em {
    font-style: normal;
}

.hoot-button {
  @apply rounded-lg h-14
}

.hoot-button.md {
  @apply rounded-lg h-11
}

button.primary {
  @apply bg-primary text-white
}

button.secondary {
  @apply bg-secondary text-header
}

button.ghost {
  @apply bg-transparent text-header
}

button.gold {
  @apply bg-gold text-white
}

h1 {
  @apply text-title-60 font-bold
}

h2 {
    @apply text-title-42 font-bold
}

h3 {
  @apply text-title-32 font-bold
}

h4 {
  @apply text-title-28 font-bold
}

h5 {
  @apply text-title-24 font-bold m-0
}

:root {
  color-scheme: only light;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: normal;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  display: flex;
  margin: 0;
  background: white;//rgba(248, 249, 252, 1);
}

input[type="text"], input[type="search"], input[type="password"] {
  @apply h-11 md:h-14;
  padding: 0px 16px 0px 16px;
  border-radius: 8px;
  border: solid 1.5px rgba(27, 31, 59, 0.15);
  gap: 10px;
  flex: 1;
}

input[type="text"].no-grow, input[type="search"].no-grow, input[type="password"].no-grow {
  flex: 0;
}

input:focus {
  border: 1.5px solid rgba(52, 117, 245, 1);
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    margin: 0;
    padding: 0;
}

button {
  font-size: inherit;
  margin: 0;
  padding: 0;
  border: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
  opacity: 0.9;
  color: inherit;
}

a:active {
  opacity: 0.8;
  color: inherit;
}

a:disabled {
  opacity: 0.5;
  color: inherit;
}

.card {
  padding: 2em;
}

.outline {
  outline: solid 1px;
}

#app {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: white;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
}

