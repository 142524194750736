.user {
  dd {
    @apply text-body-18 font-bold text-std-text;

  &.primary {
      @apply text-primary;
    }

    &.error {
      @apply text-error;
    }
  }

  dt {
    @apply text-body-14 font-normal text-std-text opacity-60;
  }
}
