.banner {
  @apply w-[400px] sm:w-[1310px] md:mx-auto max-sm:w-full;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid rgba(27, 31, 59, 0.15);
  border-radius: 24px;
  justify-content: space-between;
  //margin: 0 64px 0 64px;

  //margin: 0 auto;

  img {
    border-radius: 24px 24px 0 0;
    width: 1310px;
  }

  header {
    @apply max-sm:flex-col max-sm:gap-4;
/*    height: 58px;*/
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }

  .headings {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .headings span {
    opacity: .6;
    text-align: left;
  }

  .ctas {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}


