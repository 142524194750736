.key-features {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

}
