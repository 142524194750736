@use '../../colors' as *;
@use '../../spacing' as *;
@use '../../functions' as *;

.search {
  @apply flex-wrap max-sm:w-full;
  display: flex;
  flex-direction: row;
  gap: $gap-16;


  input {
    background-repeat: no-repeat;
    height: 56px;
    border: 1px solid $text-faint;
    border-radius: 8px;
    padding: $padding-small;
    position: relative;
    width: 122px;
    box-sizing: border-box;
    flex-grow: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    &:focus {
      outline: none;
      border: 1px solid $border-focus;
    }

    &:hover {
      border: 1px solid $border-focus;
    }
  }

  .input-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    position: relative;

    &:after {
      content: "";
      background-image: url("../../assets/icons/24/search-grey.svg");
      height: 24px;
      width: 24px;
      display: block;
      top: 16px;
      left: 16px;
      position: absolute;

      @media screen and (max-width: 450px) {
        top: 10px;
      }
    }

    input {
      padding: 0 $s16 0 adjust-for-icon-24($s16, $gap-12);
      width: 100%;
      flex: 1;


      &:focus {
        outline: none;
        border: 1px solid $border-focus;
      }

      &:hover {
        border: 1px solid $border-focus;
      }
    }
  }
}
