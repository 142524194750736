.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

li.ais-Hits-item:empty {
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  opacity: .6;
}

.text-block {
  @apply flex flex-col gap-[42px] py-8;

  header {
    @apply flex flex-col gap-2;
  }

  .subheader {
    @apply gap-0;
  }

  h3 {
    @apply text-body-24 text-primary;
  }

  h4 {
    @apply text-body-18 text-header;
  }

  section {
    @apply flex flex-col gap-6;
  }

  .headed-ol {
    @apply flex flex-col gap-4;
  }

  ol {
    @apply list-decimal list-inside flex flex-col gap-4;

    li::marker {
      @apply text-body-18 font-semibold;
    }

    li > * {
      @apply w-full;
      display: block;
    }

    h4 {
      @apply text-body-18 inline-flex mr-auto w-fit;
    }

    p {
        @apply text-body-16 font-normal;
    }
  }

  & > ol {
    @apply flex flex-col gap-8;

    li {
      @apply flex flex-col gap-6;

      p {
        opacity: .6;
      }

      header {
        @apply flex flex-col gap-2;
      }

      .header-ol {
        @apply flex flex-col gap-4;
      }

      div {
        @apply flex flex-col gap-6;
      }

      h3 {
        @apply text-body-24 inline text-primary;
      }

      ul {
        @apply list-disc list-inside text-body-16;

        li {
          @apply list-disc list-item text-std-text;
          opacity: .6;
        }

        li::marker {
          @apply text-std-text text-primary;
        }
      }
    }

    li::marker {
      @apply text-body-24 font-semibold text-primary;
      float: left;
    }

  }
}

@media screen and (max-width: 450px) {
  .text-block {
    @apply flex flex-col gap-[42px] py-8;

    header {
      @apply flex flex-col gap-2;
    }

    .subheader {
      @apply gap-0;
    }

    h3 {
      @apply text-body-18 sm:text-body-24 text-primary;
    }

    h4 {
      @apply text-body-18 text-header;
    }

    section {
      @apply flex flex-col gap-6;
    }

    .headed-ol {
      @apply flex flex-col gap-4;
    }

    ol {
      @apply list-decimal list-inside flex flex-col gap-4;

      li::marker {
        @apply text-body-18 font-semibold;
      }

      li > * {
        @apply w-full;
        display: block;
      }

      h4 {
        @apply text-body-18 inline-flex mr-auto w-fit;
      }

      p {
        @apply text-body-16 font-normal;
      }
    }

    & > ol {
      @apply flex flex-col gap-8;

      li {
        @apply flex flex-col gap-6;

        p {
          opacity: .6;
        }

        header {
          @apply flex flex-col gap-2;
        }

        .header-ol {
          @apply flex flex-col gap-4;
        }

        div {
          @apply flex flex-col gap-6;
        }

        h3 {
          @apply text-body-24 inline text-primary;
        }

        ul {
          @apply list-disc list-inside text-body-16;

          li {
            @apply list-disc list-item text-std-text;
            opacity: .6;
          }

          li::marker {
            @apply text-std-text text-primary;
          }
        }
      }

      li::marker {
        @apply text-body-24 font-semibold text-primary;
        float: left;
      }

    }
  }
}
