@use '../../spacing' as *;

.job-details {
  .tag.location {
    padding-top: 3px;
  }

  main {
    @apply w-full sm:max-w-[1310px] py-0 xl:grid mx-auto;
  }

  aside {
    @apply max-xl:w-full md:max-xl:grid;
    grid-template-columns: auto auto;
  }
}
