.react-aria-Slider {
  --label-color: var(--spectrum-alias-text-color);
  --track-color: gray;
  --thumb-color: slateblue;
  --thumb-color-dragging: lch(from slateblue calc(l - 10%) c h);
  --focus-ring-color: slateblue;

  display: grid;
  grid-template-areas: "label output"
                       "track track";
  grid-template-columns: 1fr auto;
  max-width: 300px;
  color: var(--label-color);

  .react-aria-Label {
    grid-area: label;
  }

  .react-aria-SliderOutput {
    grid-area: output;
  }

  .react-aria-SliderTrack {
    grid-area: track;
    position: relative;

    /* track line */
    &:before {
      @apply bg-primary;
      content: '';
      display: block;
      position: absolute;
    }
  }

  .react-aria-SliderThumb {
    @apply bg-primary;
    width: 1.429rem;
    height: 1.429rem;
    border-radius: 50%;
    border: 2px solid var(--spectrum-alias-background-color-default);

    &[data-dragging] {
      //background: var(--thumb-color-dragging);
    }

    &[data-focus-visible] {
      box-shadow: 0 0 0 2px var(--focus-ring-color);
    }
  }

  &[data-orientation=horizontal] {
    flex-direction: column;
    width: 300px;

    .react-aria-SliderTrack {
      height: 30px;
      width: 100%;

      &:before {
        height: 3px;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .react-aria-SliderThumb {
      top: 50%;
    }
  }
}

@media (forced-colors: active) {
  .react-aria-Slider {
    forced-color-adjust: none;

    --label-color: ButtonText;
    --track-color: ButtonBorder;
    --thumb-color: ButtonBorder;
    --thumb-color-dragging: ButtonBorder;
    --focus-ring-color: Highlight;
    --spectrum-alias-background-color-default: Canvas;
  }
}
