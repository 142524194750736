@use '../../../colors' as colors;

.faq {
  display: flex;
  flex-direction: column;
  background: colors.$border;
  padding: 20px;
  gap: 16px;
  border-bottom: solid 1px black;

  &:last-child {
    border: none;
  }
}
