.react-aria-Select {
  --border-color: var(--spectrum-alias-border-color);
  --border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --text-color: var(--spectrum-alias-text-color);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --focus-ring-color: #3475F5;

  .react-aria-Button {
    background: var(--spectrum-global-color-gray-50);
    box-shadow: 0 1px 2px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    font-size: 1.072rem;
    padding: 0.286rem 0.286rem 0.286rem 0.571rem;
    display: flex;
    align-items: center;
    max-width: 250px;

    &[data-pressed] {
      background: var(--spectrum-global-color-gray-150);
    }

    &[data-focus-visible] {
      box-shadow: 0 0 0 1px var(--focus-ring-color);
    }
  }

  .react-aria-SelectValue {
    &[data-placeholder] {
      font-style: italic;
      color: var(--spectrum-global-color-gray-700);
    }
  }

  & span[aria-hidden] {
    width: 1.5rem;
    line-height: 1.375rem;
    margin-left: 1rem;
    padding: 1px;
    background: #3475F5;
    color: white;
    border-radius: 4px;
    font-size: 0.857rem;
  }
}

@layer popover {
  .react-aria-Popover {
    min-width: var(--trigger-width);
    padding: 0;
  }
}

@layer listbox {
  .react-aria-ListBox {
    display: block;
    width: unset;
    max-height: inherit;
    min-height: unset;
    border: none;
    background: white;
    padding: 20px;

    .react-aria-Header {
      padding-left: 1.571rem;
    }
  }

  .react-aria-ListBoxItem {
    padding: 0.286rem 0.571rem 0.286rem 1.571rem;

    &[data-focus-visible] {
      box-shadow: unset;
    }

    &[data-selected] {
      font-weight: 600;
      background: unset;
      color: var(--text-color);

      &::before {
        content: '✓';
        content: '✓' / '';
        alt: ' ';
        position: absolute;
        top: 4px;
        left: 4px;
      }

      &[data-focus-visible] {
        box-shadow: unset;
      }
    }

    &[data-focused],
    &[data-pressed] {
      background: var(--highlight-background);
      color: var(--highlight-foreground);
    }
  }
}

@media (forced-colors: active) {
  .react-aria-Select {
    --border-color: ButtonBorder;
    --border-color-disabled: GrayText;
    --text-color: ButtonText;
    --text-color-disabled: GrayText;
    --focus-ring-color: Highlight;
  }
}
