@use 'functions' as *;

$s64: 64px;
$s60: 60px;
$s32: 32px;
$s16: 16px;
$s10: 10px;

$padding-large: $s60 $s64 $s60 $s64;
$padding-small: 0 $s16;

$gap-4: 4px;
$gap-12: 12px;
$gap-16: 16px;
$gap-20: 20px;
$gap-24: 24px;
$gap-32: 32px;
$gap-48: 48px;

$border-radius: 16px;

$p-lg: 64px;
$p-lg2: 60px;
$p-md: 32px;
$p-md2: 20px;
$p-md3: 16px;
$p-sm: 10px;

$padding-small-icon-16: 0 $s16 0 adjust-for-icon-16($s16, $gap-4);
$padding-small-icon-24: 0 $s16 0 adjust-for-icon-24($s16, $gap-4);
