@use "../../colors" as *;
@use "../../functions" as *;
@use "../../spacing" as *;
@use 'sass:math';

.job-edit {
  display: flex;
  flex-direction: column;
  //border-radius: $border-radius;
  border: solid 1px $border;
  padding: 32px;
  border-radius: 20px;
  gap: 20px;
  box-sizing: border-box;
  width: 100%;
  background: $white;

  .url-input-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gap-20;

    .checkboxInput {
      width: 24px;
      height: 24px;
      border-radius: math.div($border-radius, 2);
      border: solid 1px $border;
      margin-right: 16px;
      flex-grow: 0;
    }

    .url-entry-holder {
      flex: 1;
      position: relative;

      .navigateLink {
        position: absolute;
        background-image: url('../../assets/icons/24/link.svg');
        height: 58px;
        width: 58px;
        content: '';
        display: block;
        background-repeat: no-repeat;
        top: 0;
        left: 0;
        background-position: center;
        opacity: .3;
      }

      .copyLink {
        position: absolute;
        background-image: url('../../assets/icons/24/copy.svg');
        height: 58px;
        width: 58px;
        content: '';
        display: block;
        background-repeat: no-repeat;
        top: 0;
        right: 0;
        background-position: center;
        opacity: .3;
        border: none;
        padding: 0;
        background-color: rgb(256, 256, 256);
        cursor: pointer;
      }

      .url-input {
        height: 56px;
        border-radius: math.div($border-radius, 2);
        border: solid 1px $border;
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px 0 adjust-for-icon-24(16, 10px);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: $gap-32;
    text-align: left;
    justify-content: flex-start;


    .location {
      display: flex;
      flex-direction: column;
      gap: $gap-12;
      flex-basis: 300px;
      flex-grow: 1;
      justify-content: space-between;

      .header {
        display: flex;
        flex-direction: row;
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;

        .headings {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .job {
      display: flex;
      flex-direction: column;
      gap: $gap-20;
      flex-basis: 700px;
      justify-self: flex-start;
      text-align: left;
      flex-grow: 1;

      .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;

        .header h3, .header h4 {
          justify-self: flex-start;
          text-align: left;
        }
      }
    }
  }

  .main-body.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 102px;
  }

  .ctas {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
  }


}
