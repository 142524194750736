.search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .input-holder {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;

    input {
      border-radius: 8px;
      height: 56px;
      padding: 0 16px;
      border: 1.5px solid #1B1F3B26;
      flex: 1;
    }

    button {
      height: 56px;
      width: 56px;
      background: #3475F5;
    }
  }
}
