@use '../../_colors.scss' as *;
/* Color=Default, Style=Default, Size=56, Disabled=False, State=Default */

.hoot-button.blue {
  background: #3475F5;
  color: #FFFFFF;
}

.hoot-button.white {
  color: #3475F5;
  background: #FFFFFF;
}

.hoot-button.light-blue {
  @apply text-primary;
  background: rgba(18, 116, 254, 0.08);
}

.hoot-button.light-red {
  @apply text-error;
  background: rgba(253, 57, 118, 0.08);
}

.hoot-button.red {
  background: #FD3976;
}

.hoot-button.gold {
  @apply bg-gold;
}

.hoot-button {
  @apply gap-2;
  display: flex;

  &.expandable {
    flex: 1;
  }

  &.blue.tertiary {
    background: rgba(18, 116, 254, 0.08);
    color: #3475F5;
  }

  &.red.tertiary {
    color: #FD3976;
    background: rgba(253, 57, 118, 0.08);
  }

  &.white.tertiary {
    color: $white;
    background: $white;
  }

  &.outline {
    background: none;
  }

  &.gold.outline {
    @apply text-gold border-gold;
    border: 1px solid;
  }

  &.blue.outline {
    color: #3475F5;
    border: 1px solid #3475F5;
  }

  &.red.outline {
    color: $secondary;
    border: 1px solid rgba(253, 57, 118, 0.08);
  }

  &.outline.white {
    color: $white;
    border: 1px solid $white;
  }

  &.outline.grey {
    color: $text-faint;
    border: 1px solid $text-faint;
  }

  &.blue.space {
    color: #3475F5;
    border: none;
  }

  &.red.space {
    color: rgba(253, 57, 118, 0.08);
    border: none;
  }

  &.space {
    background-color: unset;
  }
}

.hoot-button:disabled {
  opacity: .5;
}

.hoot-button.btn-md {
  height: 56px;
}

.hoot-button.icon.btn-md {
  padding: 0 16px 0 48px;
}

.hoot-button.btn-md {
  padding: 0 16px;
}

.hoot-button.btn-sm {
  @apply h-8 md:h-11;
}

.hoot-button.icon.btn-sm {
  padding: 0 10px;
}

.hoot-button.btn-sm {
  padding: 0 10px;
}

.hoot-button.btn-xs {
  height: 32px;
}

.hoot-button.icon.btn-xs {
  padding: 0 8px 0 30px;
  height: 32px;
}

.hoot-button {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  border: 0;
}

.hoot-button:hover {
  opacity: 0.9;
}

.hoot-button:active {
  opacity: 0.8;
}

.hoot-button:disabled {
  opacity: 0.5;
}

.hoot-button.icon::before {
  width: 24px;
  height: 24px;
  content: '';
  display: block;
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 16px;
  left: 16px;
}

.hoot-button.icon.btn-sm::before {
  top: 10px;
  left: 10px;
}

.hoot-button.icon.btn-xs::before {
  width: 16px;
  height: 16px;
  top: 8px;
  left: 8px;
}
