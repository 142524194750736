.ais-Hits-list {
  @apply flex gap-8 flex-wrap justify-center;

  .ais-Hits-item:last-child {
    @media screen and (min-width: 2500px) {

    }
  }
}

.ais-SearchBox {
  @apply flex gap-4 items-center flex-grow gap-2;
}

.ais-SearchBox-form {
    @apply flex gap-4 items-center flex-grow;
}

.ais-SearchBox-form input {
  @apply border rounded-md h-10 px-4 flex-grow h-14;
  border-color: rgba(27, 31, 59, 0.15);
}

.ais-SearchBox-form button {
  @apply bg-primary w-14 h-14 rounded-md flex items-center justify-center text-white;
}

.ais-SearchBox-form button svg {
  fill: white;
  height: 20px;
  width: 20px;
}

.ais-SearchBox-form button[type='reset'] {
  display: none;
}
