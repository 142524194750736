@use 'colors' as *;

body {
    @apply text-body-14 md:text-body-16;
    font-family: Inter;
    letter-spacing: 0em;
    text-align: left;
    color: $text;


}

.primary-color {
    color: $primary;
}

.white {
    color: $white;
}

h1, h2, h3, h4 {
    font-weight: 700;
    text-align: left;
    letter-spacing: 0em;
    margin: 0;
}

h1 {
    font-size: 3.75rem ; /* 60px */
    line-height: 4.5rem; /* 72px */

    @media (max-width: 640px) { /* 640px is the default value for 'sm' in Tailwind */
        font-size: 32px;
        line-height: 120%;
    }
}

h2 {
    font-size: 2.625rem;  /* 42px */
    line-height: 3.375rem; /* 54px */

    @media (max-width: 640px) { /* 640px is the default value for 'sm' in Tailwind */
        font-size: 26px;
        line-height: 128%;
    }
}

h3 {
    font-size: 2rem; /* 32px */
    line-height: 2.5625rem; /* 41px */

    @media (max-width: 640px) { /* 640px is the default value for 'sm' in Tailwind */
        font-size: 22px;
        line-height: 128%;
    }
}

h4 {
    @apply text-title-20 md:text-title-28;
}

.std h1, .std h2, .std h3, .std h4 {
    line-height: 150%;
    font-weight: 400;
}

.std {
    line-height: 150%;
}

.w400 {
    font-weight: 400 !important;
}

.w600 {
    font-weight: 600 !important;
}

.w700 {
    font-weight: 700 !important;
}

.op600 {
    opacity: 0.6;
}

.op800 {
    opacity: 0.8;
}

strong, em, .bold, .std strong, .std em, .std .bold {
    font-weight: 600;
}

.xl, .std .xl {
    font-size: 1.5rem; /* 24px */
    line-height: 140%;
}

.lg, .std .lg {
    font-size: 1.125rem; /* 18px */
}

.md, .std .md, .std {
    font-size: 1rem; /* 16px */
}

.sm, .std .sm {
    @apply text-body-12 md:text-body-14;
}

.xs, .std .xs {
    font-size: 0.75rem; /* 12px */
    line-height: 150%;
}
