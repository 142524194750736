.react-aria-Checkbox {
  --label-color: var(--spectrum-alias-text-color);
  --deselected-color: gray;
  --deselected-color-pressed: dimgray;
  --selected-color: slateblue;
  --selected-color-pressed: lch(from slateblue calc(l - 10%) c h);
  --checkmark-color: white;
  --invalid-color: var(--spectrum-global-color-red-600);
  --invalid-color-pressed: var(--spectrum-global-color-red-700);

  display: flex;
  align-items: center;
  gap: 0.571rem;
  font-size: 1.143rem;

  .checkbox {
    @apply h-6 w-6;
    border: 2px solid var(--deselected-color);
    border-radius: 4px;
    transition: all 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    width: 1rem;
    height: 1rem;
    fill: none;
    stroke: var(--checkmark-color);
    stroke-width: 3px;
    stroke-dasharray: 22px;
    stroke-dashoffset: 66;
    transition: all 200ms;
  }

  &[data-pressed] .checkbox {
    border-color: var(--deselected-color-pressed);
  }

  &[data-focus-visible] .checkbox {
 }
  &[data-selected],
  &[data-indeterminate] {
    .checkbox {
      @apply border-primary bg-primary;
    }

    &[data-pressed] .checkbox {
      border-color: var(--selected-color-pressed);
      background: var(--selected-color-pressed);
    }

    & svg {
      stroke-dashoffset: 44;
    }
  }

  &[data-indeterminate] {
    & svg {
      stroke: none;
      fill: var(--checkmark-color);
    }
  }
}

@media (forced-colors: active) {
  .react-aria-Checkbox {
    forced-color-adjust: none;

    --label-color: ButtonText;
    --deselected-color: ButtonBorder;
    --deselected-color-pressed: ButtonBorder;
    --selected-color: Highlight;
    --selected-color-pressed: Highlight;
    --checkmark-color: HighlightText;
    --invalid-color: LinkText;
    --invalid-color-pressed: LinkText;
    --spectrum-alias-background-color-default: Canvas;

    &[data-disabled] {
      --deselected-color: GrayText;
      --selected-color: GrayText;
      --label-color: GrayText;
    }
  }
}
