.text-feature {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
  }

  h2 {
    @apply text-title-28 sm:text-title-42;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
  }

  &.gold em, &.gold h3 {
    @apply text-gold;
  }

  &.primary em, &.primary h3 {
    @apply text-primary;
  }

}
