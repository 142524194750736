@use '../../colors' as *;
@use '../../spacing' as *;

.footer {
  @apply md:h-[199px] gap-[32px] md:gap-[128px] flex-col md:flex-row;
  width: 100%;
  padding: $p-md;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: rgba(52, 117, 245, 1) url("../../assets/images/footer-hoot.svg") no-repeat right bottom;
  color: $white;justify-content: space-between;

  @media (max-width: 480px) {
    background: rgba(52, 117, 245, 1) url("../../assets/images/footer-hoot.svg") no-repeat right top;
    background-size: 600px 200px;
    background-position-x: 200px;
    background-position-y: -80px;
  }

  .go-up {
    background: rgb(72, 131, 246) !important;
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: $gap-16;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      opacity: .6;
      text-align: left;
    }
  }

  button {
    background: rgba(255, 255, 255, .1);
    max-width: 840px;
    margin-right:32px;
    cursor: pointer;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(255, 255, 255, 1);
    width: 132px;

    span {
      opacity: .5;
      text-align: left;
    }
  }
}
