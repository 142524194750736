.splash {
  @apply px-8 py-12 md:py-15 md:px-16;
  display: flex;
  flex-direction: column;
  gap: 32px;


  .main {
    @apply flex-wrap lg:flex-nowrap gap-8 md:gap-200 relative max-sm:w-full;
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .text-panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #FFFFFF;

    h1 {
      @apply max-sm:w-315 w-550;
    }

    .stats {
      display: flex;
      flex-direction: row;
      gap: 32px;

      .stat {
        text-align: left;
      }
    }
  }

  .search-panel {
    @apply sm:w-full w-full max-sm:bottom-minus330;

    @media (max-width: 480px) {
      box-shadow: 0px 9px 53px 0px rgba(18, 116, 254, 0.15);
    }
    //min-width: 640px;
    padding: 32px;
    border-radius: 22px;
    gap: 32px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    max-width: 850px;
  }

  .carousel {
    border-radius: 13px;
    height: 79px;
    width: 100%;
    background: #FFFFFF;
  }
}
