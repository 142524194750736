.react-aria-DropZone {
  @apply w-[500px];

  --border-color: var(--spectrum-alias-border-color);
  --border-color-pressed: var(--spectrum-alias-border-color-focus);
  --background-color: var(--spectrum-global-color-gray-50);
  --background-color-pressed: var(--spectrum-global-color-gray-100);
  --text-color: var(--spectrum-alias-text-color);
  --focus-ring-color: var(--spectrum-alias-border-color-focus);

  color: var(--text-color);
  background: var(--background-color);
  border: 1px solid #3475F5;
  border-radius: 4px;
  appearance: none;
  vertical-align: middle;
  font-size: 1.2rem;
  text-align: center;
  margin: 0;
  outline: none;
  padding: 24px 12px;

  display: inline-block;

  &[data-drop-target] {
    box-shadow: 0 0 0 1px var(--focus-ring-color);
    background: var(--background-color-pressed);
    border-color: var(--border-color-pressed);
  }

  &[data-focus-visible] {
    border-color: var(--focus-ring-color);
    box-shadow: 0 0 0 1px var(--focus-ring-color);
  }
}

@media (forced-colors: active) {
  .react-aria-DropZone {
    forced-color-adjust: none;
    --border-color: ButtonBorder;
    --border-color-drop-target: ButtonBorder;
    --background-color: ButtonFace;
    --background-color-pressed: ButtonFace;
    --text-color: ButtonText;
    --focus-ring-color: Highlight;
  }
}
