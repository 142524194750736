/* Button */
.ais-Dropdown-button {
  font-weight: 600;
  padding: 0 1rem 0 1.5rem;
  cursor: pointer;
  appearance: none;
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 25px;
  box-shadow: 0 1px 0 0 rgb(35 38 59 / 5%);
  color: #23263b;
  user-select: none;

}

.ais-RefinementList-labelText {
  width: 250px;
  overflow: hidden;
}

.ais-Dropdown-button.primary-one {
  @apply bg-primary text-white;
  background: #3475F5;

  &:hover {
    background: #3475F5 !important;
  }
}

.ais-Dropdown-button:hover {
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
}

.ais-Dropdown-button:disabled[disabled] {
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
  box-shadow: none;
  color: #b6b7d5;
}

.ais-Dropdown-button--refined {
  font-weight: bold;
}

/* Panel */
.ais-Dropdown.ais-Panel {
  margin: 0;
}

.ais-Panel-body {
  translate: -25%;
}

.ais-RefinementList-list {
  display: flex;
  flex-direction: column;
}

.ais-RefinementList-item {
  padding: 0.8rem;
}

.ais-RefinementList-label {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  font-weight: 600;
}

.ais-RefinementList-labelText {
  flex: 1;
}

.ais-RefinementList-count {
  width: 25px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
    color: #3475F5;
}

.ais-RefinementList-count:before {
  content: '(';
}

.ais-RefinementList-count:after {
  content: ')';
}

.ais-RefinementList-item--selected, .ais-RefinementList-item:hover {
  background: rgba(18, 116, 254, 0.08);
}

.ais-Dropdown .ais-Panel-body {
  position: absolute;
  border: 1px solid #d6d6e7;
  border-radius: 25px;
  margin-top: 0.5rem;
  background-color: #fff;
  overflow: hidden;
}

.ais-Dropdown .ais-Panel-header {
  margin-bottom: 0;
}

.ais-Dropdown .ais-Panel-body {
  display: none;
}

.ais-Dropdown .ais-Panel-footer {
  margin: 0;
}

.ais-Dropdown--opened .ais-Panel-body {
  display: block;
}

/* Mobile */
.ais-Dropdown-close {
  display: none;
  width: 100%;
  margin: 0 1rem 1rem;
}

.ais-Dropdown-mobileTitle {
  display: none;
  margin-top: 0;
}

@media only screen and (max-width: 375px) {
  .ais-Dropdown.ais-Dropdown--opened .ais-Panel-body {
    position: fixed;
    inset: 0;
    margin: 0;
    background: #fff;
    padding-bottom: 4rem;
    overflow-y: auto;
  }

  .ais-Dropdown.ais-Dropdown--opened .ais-Panel-footer {
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: center;
    bottom: 0;
    left: 0;
    background: #fff;
  }

  .ais-Dropdown--opened .ais-Dropdown-close {
    display: inline;
  }

  .ais-Dropdown-mobileTitle {
    display: block;
  }
}
