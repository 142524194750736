@use '../../spacing' as *;

.copy {
  @apply sm:w-[752px] w-full;
  display: flex;
  flex-direction: column;
  gap: $gap-48;

  .location-details {
    gap: $gap-12;
  }

  .supporting-documents, .map-pane {
    gap: $gap-24;

    .map {
      height: 376px;
      border-radius: 16px
    }
  }

  .main-copy {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: $gap-16;
    text-align: left;
  }
}
