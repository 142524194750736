.header {
  @apply px-8 md:px-16 py-5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  box-sizing: border-box;

  .logo-holder, .ctas {
    display: flex;
    align-items: center;
    width: 382px;
    flex-shrink: 1;
  }

  .menu {
    @apply max-xl:hidden flex;
    //display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 24px;
    justify-content: space-between;

    width: 509px;
    height: 24px;
  }

  .menu a {
    text-wrap: nowrap;
  }

  .ctas {
    justify-content: flex-end;
    gap: 12px;
  }

}

.react-aria-ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--visual-viewport-height);
  background: rgba(0 0 0 / .5);
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-entering] {
    animation: modal-fade 200ms;
  }

  &[data-exiting] {
    animation: modal-fade 150ms reverse ease-in;
  }
}

.react-aria-ModalOverlay {
  @apply z-20;
}

.react-aria-Modal {
  box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
  border-radius: 6px;
  background: var(--page-background);
  border: 1px solid var(--spectrum-global-color-gray-300);
  outline: none;


  &[data-entering] {
    animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .react-aria-TextField {
    margin-bottom: 8px;
  }
}

@keyframes modal-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-zoom {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}


