.time {
  width: 155px;
  display: flex;
  flex-direction: row;
  gap: 16px;

}

.apply, .start {
  @apply md:gap-[2px];
  display: flex;
  flex-direction: column;
}

.apply {
  margin-right: auto;
}

.start {
  margin-left: auto;
}

.apply span, .start span {
  display: inline-block;
  font-weight: 600;
}

.label {
  opacity: .6;
}

.apply .value {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
