@use '../../colors' as *;
@use '../../spacing' as *;
@use '../../functions' as *;
@use 'sass:math';

input:read-only, input:read-only:focus, textarea:read-only, textarea:read-only:focus {
  @apply bg-bg-emphasis border-0;
  border: none !important;
  outline: 1px;
  opacity: 0.8;
}

input {
  outline: none;
}

.edit-page {
  display: flex;
  min-height: 100vh;

  .ais-Hits-item {
    width: 100%;
  }

  img {
    max-width: none;
  }

  &.menu-collapsed {
    .collapse-btn::before {
      transform: rotate(180deg);
    }

    .sidebar {
      width: 96px;
      padding: 20px 8px 20px 8px;
      box-sizing: border-box;

      .top {
        justify-content: center;
        gap: 0;
        width: 76px;
      }

      .logo-holder {
        width: 20px;
      }

      .logo {
        transform: translateX(-80px);
        transition: transform .3s ease-in-out;
      }

      ul {
        padding-left: 10px;
        padding-right: 10px;
      }

      .g {
        font-size: 0;
      }

      a {
        font-size: 0;
        width: 56px;

        padding: 16px;

        &.half:before {
          transform: rotate(180deg);
        }
      }
    }

    .main {
      padding-left: 92px;
    }
  }



  .sidebar {
    height: 100vh;
    width: 306px;
    border: solid 1.5px $border;
    padding: $p-md2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: fixed;
    transition: all .3s ease-in-out;
    background-color: #FFFFFF;
    align-items: center;
    overflow: hidden;

    .g {
      text-wrap: nowrap;
    }

    .top {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 32px;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        color: $primary;
      }

      .logo-holder {
        overflow: hidden;
      }

      .logo {
        height: 34px;
      }
    }

    .top-menu {
      margin-bottom: auto;
    }

    .bottom-menu {
      padding-top: 16px;
      border-top: solid 1.5px $border;

      & a:hover {
        color: rgba(253, 57, 118, 1);
        background-color: rgba(253, 57, 118, 0.08);
      }
    }

    ul {
      width: 100%;
      box-sizing: border-box;
    }

    li {
      text-align: left;
      overflow: hidden;
      height: 56px;
      box-sizing: border-box;

      a {
        padding: $p-md3 $p-md3 $p-md3 adjust-for-icon-24($p-md3, 8px);
        border-radius: $border-radius;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-wrap: nowrap;
        position: relative;
        width: 100%;

        &.web-scrape:before {
          background-image: url('../../assets/icons/24/briefcase.svg');
        }

        &.hoot-schools:before {
          background-image: url('../../assets/icons/24/star.svg');
        }

        &.web-scrape:before {
          background-image: url('../../assets/icons/24/briefcase.svg');
        }

        &.users:before {
          background-image: url('../../assets/icons/24/user.svg');
        }

        &.collapse-btn:before {
          background-image: url('../../assets/icons/24/Collapse.svg');
        }

        &.logout:before {
          background-image: url('../../assets/icons/24/log-out.svg');
        }

        &.web-scrape:hover:before {
          background-image: url('../../assets/icons/24/briefcase-blue.svg');
        }

        &.hoot-schools:hover:before {
          background-image: url('../../assets/icons/24/star-blue.svg');
        }

        &.other-schools:hover:before {
          background-image: url('../../assets/icons/24/book-open-blue.svg');
        }

        &.users:hover:before {
          background-image: url('../../assets/icons/24/users-blue.svg');
        }

        &.collapse-btn:hover:before {
          background-image: url('../../assets/icons/24/Collapse-red.svg');
        }

        &.logout:hover:before {
          background-image: url('../../assets/icons/24/log-out-red.svg');
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          height: 56px;
          width: 56px;
          content: '';
          display: block;
          background-image: url('../../assets/icons/24/book-open.svg');
          background-repeat: no-repeat;
          background-position: 16px center;
          transition: all .3s ease-in-out;
        }

        &:hover {
          background-color: $primary-mid-light;
          color: $primary;
        }
      }
    }
  }

  .main {
    padding-left: 306px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(248, 249, 252);
    transition: all .3s ease-in-out;
  }

  .top-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    box-sizing: border-box;
    align-items: center;
    border-bottom: solid 1px $border;
    position: sticky;
    top: 0;


    background-color: #FFFFFF;

    .review .number {
      color: $primary;
    }

    .ctas {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  main {
    width: 100%;
    height: 100%;
    background: rgb(248, 249, 252);
    padding: $p-md 0 $p-md $p-md;
    gap: $gap-32;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

  }

  .react-aria-ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--visual-viewport-height);
    background: rgba(0 0 0 / .5);
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-entering] {
      animation: modal-fade 200ms;
    }

    &[data-exiting] {
      animation: modal-fade 150ms reverse ease-in;
    }
  }

  .react-aria-Modal {
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    background: var(--page-background);
    border: 1px solid var(--spectrum-global-color-gray-300);
    outline: none;


    &[data-entering] {
      animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .react-aria-TextField {
      margin-bottom: 8px;
    }
  }

  @keyframes modal-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-zoom {
    from {
      transform: scale(0.8);
    }

    to {
      transform: scale(1);
    }
  }

}

