.edit-aside {
  button {
    @apply text-std-text stroke-std-text;
  }

  button.current {
    @apply text-primary stroke-primary;
  }

  .cta {
    @apply text-white flex gap-2;
    position: absolute;
    top: 0px;
    right: 8px;

    .cta-button {
      padding: 8px;
      border-radius: 8px;

      &.primary {
        @apply bg-primary;
      }
    }
  }

  .close-button {
    @apply text-white;
    position: absolute;
    top: -30px;
    right: -30px;
    padding: 8px;
    border-radius: 8px;
  }
}
