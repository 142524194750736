.job-card {
  &.job {
    @apply md:min-w-415 md:w-415;

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;

    position: relative;
    width: 354px;
    height: 285px;

    box-sizing: border-box;

    @media screen and (max-width: 767px){
      height: 245px;
      padding: 16px;
    }

    @media (max-width: 394px) { /* 640px is the default value for 'sm' in Tailwind */
      width: 100%;

    }

    background: #FFFFFF;
    border: 1px solid rgba(27, 31, 59, 0.15);
    border-radius: 16px;
    margin: 2px;

    & button {
      cursor: pointer;
    }

    &:hover {
      box-shadow: 0 67px 27px rgba(52, 116, 245, 0.01), 0 38px 23px rgba(52, 116, 245, 0.05), 0 17px 17px rgba(52, 116, 245, 0.09), 0 4px 9px rgba(52, 116, 245, 0.1), 0 0 0 rgba(52, 116, 245, 0.1);
      transform: scale(1.1);
    }

    &.applied {
      border: 3px solid #3475F5;
      margin: 0;
    }

    .tags {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      gap: 8px;

      width: 376px;
      height: 32px;

      @media (max-width: 394px) { /* 640px is the default value for 'sm' in Tailwind */
        width: 100%;

      }
    }

    .header {
      @apply w-310 md:w-376;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      gap: 12px;
      height: 64px;

      @media (max-width: 394px) { /* 640px is the default value for 'sm' in Tailwind */
        width: 100%;

      }

      .headings {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;

        .job-title {
          height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .job-location {
          height: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .image-holder {
        height: 64px;
        border-radius: 12px;
        margin-left: auto;
      }
    }

    .description {
      flex: 1;

      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @media screen and (max-width: 767px){
        -webkit-line-clamp: 2;
      }


      & p {
        margin: 0;
      }
    }

    .ctas {
      justify-self: flex-end;
      display: flex;
      flex-direction: row;
      gap: 14px;
      align-items: center;

      button {
        @apply w-140 md:w-205;
      }
    }
  }
}
