@use '../../colors' as *;
@use '../../functions' as *;

.tag {
  @apply text-body-12 md:text-body-14;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  width: fit-content;
  color: rgba(27, 31, 59, 1);
}

.tag.location {
  background: $text-v-faint;
  color: $text;
  padding: 0 10px 0 adjust-for-icon-16(10px, 6px);
}

.tag.flag {
  background: rgba(52, 117, 245, 1);
  color: #FFFFFF;
  padding: 0px 0px 0px 10px;
  border-radius: 8px 0px 0px 8px;
  background: rgba(19, 111, 99, 1);
}

.tag.location::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/icons/16/map-pin.png');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 8px;
  left: 8px;
}

.tag.flag::after {
  content: '';
  display: block;
  width: 16px;
  height: 32px;
  background-image: url('../../assets/icons/32/flag.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0px;
  right: -15px;
}
