.scroller[data-animated="true"] {
  @apply overflow-hidden;
  &.fade-sides {
    -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  }

  .scroller__inner {
    flex-wrap: nowrap;
    animation: scroll linear infinite;
  }

  @keyframes scroll {
    to {
      transform: translateX(-50%);
    }
  }
}
