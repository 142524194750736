$text: rgb(27, 31, 59);
$text-faint: rgb(27, 31, 59, .15);
$text-v-faint: rgba(27, 31, 59, 0.05);

$primary: rgb(18, 116, 254);
$primary-light: rgb(248, 249, 252);
$primary-mid-light: rgba(18, 116, 254, 0.08);
$secondary: rgb(253, 57, 118);
$white: rgb(255, 255, 255);
$border: rgba(27, 31, 59, 0.15);


$border-focus: rgba(52, 117, 245, 1);
