.landing-splash {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &.teacher {
    dt {
      @apply text-gold;
    }

    button {
      @apply bg-gold;
    }
  }

  &.school {
    dt {
      @apply text-primary;
    }


  }

  em.teacher {
    @apply text-gold;
  }

  em.school {
    @apply text-primary;
  }

  .main {
    display: flex;
    flex-direction: row;
    gap: 200px;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .text-panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #FFFFFF;

    h1 {
      width: 550px;
    }

    .stats {
      display: flex;
      flex-direction: row;
      gap: 32px;

      .stat {
        text-align: left;
      }
    }
  }

  .search-panel {
    min-width: 640px;
    padding: 32px;
    border-radius: 22px;
    gap: 32px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    max-width: 850px;
    width: 100%;
  }

  .carousel {
    border-radius: 13px;
    height: 79px;
    width: 100%;
    background: #FFFFFF;
  }
}
